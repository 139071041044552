
import {
  Minimize2Icon,
  FilmIcon,
  RepeatIcon,
  LoaderIcon,
  FileTextIcon,
  FileIcon,
} from "vue-feather-icons";
export default {
  name: "NavigationToolList",
  components: {
    Minimize2Icon,
    FilmIcon,
    RepeatIcon,
    LoaderIcon,
    FileTextIcon,
    FileIcon,
  },
  props: {
    tools: {
      required: true,
      default: [],
      type: Array,
    },
  },
};
